.homeLogo img {
  height: 8rem;
  width: 8rem;
  border-radius: 100px;
  color: black;
}

.head-title {
  font-family: 'Avenir', sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: start;
  color: rgb(157, 66, 218);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.navBarTitle {
  cursor: pointer;
  font-size: 14;
  font-weight: 600;
}

.dropdown-content {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  background-color: white;
  /* border: 1px solid black; */
}

.navBarTitle {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.25;
  letter-spacing: 0.02857em;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  height: 0.5rem;
  background-color: white;
  width: 100%;
}

.bookingTitle {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Nunito', sans-serif;
}

@media screen and (max-width: 768px) {
  .head-title {
    font-size: 1rem;
  }

  .title {
    font-size: 1rem;
  }

}

@media screen and (max-width: 480px) {
  .head-title {
    font-size: .75rem;
  }

  .title {
    font-size: .75rem;
  }
}

.tooltip {
  padding: auto;
  display: flex;
  flex-direction: column;
  height: max-content;
}

.footer-title {
  color: white;
  background-color: black;
  font-size: 3rem;
}

.partnersMainContainer {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255);
  padding: 10px 10px 10px 10px;
  justify-content: center;
  align-items: flex-end;
  max-height: 92vh;
  max-width: 400px;
}

.partnersInsideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px 50px;
  padding: 20px 20px
}

.partnersImage {
  width: 15vh;
  height: 15vh;
  object-fit: contain;
}

.partnersName {
  font-family: Bacasime Antique, serif;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 8px !important;
  text-align: center;
}

.partnerImage {
  border-radius: 10px;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  cursor: pointer;
  animation: 0.3s ease-in-out slideIn;
  animation-fill-mode: forwards;
  opacity: 0
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.partnerSideNavImage {
  border-radius: 10px;
  width: 35px;
  height: 35px;
  margin-top: 20px;
  cursor: pointer;
  animation: 0.3s ease-in-out slideIn;
  animation-fill-mode: forwards;
  opacity: 0
}

@media screen and (max-width:500px) {
  .partnersMainContainer {
    width: 80%;
  }

  .partnersName {
    font-weight: 500;
    font-size: 1rem;
  }
}

@media screen and (max-width:450px) {

  .partnersImage {
    width: 12vh;
    height: 12vh;
  }

  .partnersInsideContainer {
    gap: 20px 40px;
    padding: 15px 15px
  }
}

@media screen and (max-width:380px) {

  .partnersImage {
    width: 10vh;
    height: 10vh;
  }

  .partnersInsideContainer {
    gap: 20px 20px;
    padding: 10px 10px
  }
}