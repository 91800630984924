.testimonials-container {
    max-height: fit-content;
    padding-top: 2rem;
    /* width: 100vw; */
    /* overflow: hidden; */
}

.servicesText {
    text-align: center;
    font-weight: 800;
    font-family: 'Ysabeau SC', sans-serif;
    font-size: 2.25rem;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.divider {
    height: 3px;
    /* background-color: black; */
    background-color: rgba(24, 157, 99, 0.538);
    width: 150px;
    margin-top: 10px;
}

.cardMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.influencerCard {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* -webkit-perspective: 2000px; */
    -webkit-backface-visibility: hidden;
}

.nameStyling {
    margin-top: 15px;
    font-size: 2rem;
    font-weight: 500;
    font-family: "Cormorant Garamond', serif";
}

.designationStyling {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Cormorant Garamond', serif";
    color: grey;
    margin-top: 5px;
}

.wordsStyling {
    margin-top: 15px;
    font-weight: 500;
    font-size: 1.3rem;
    letter-spacing: 1.5;
    text-align: center;
    font-family: Bacasime Antique, serif;
    margin-bottom: 1rem;
}

.influencersImage {
    width: 300px;
    height: 300px;
    border: 2px solid lightgray;
    padding: 6px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {

    .influencersImage {
        width: 250px;
        height: 250px;
    }

    .wordsStyling {
        font-size: 1.2rem;
    }

    .nameStyling{
        font-size: 1.8rem;
    }

    .designationStyling{
        font-size: 1.4rem;
    }

}

@media screen and (max-width: 480px) {
    .influencersImage {
        width: 200px;
        height: 200px;
    }

    .wordsStyling {
        font-size: 1rem;
        /* width: 80%; */
    }

    .nameStyling{
        font-size: 1.6rem;
    }

    .designationStyling{
        font-size: 1.2rem;
    }
}