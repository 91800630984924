.about-container {
  /* padding-left: 2rem;
  padding-right: 2rem; */
  padding:3rem 1rem 1rem 1rem;
  height: fit-content;
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
}

.about-text {
  width: 70%;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 1.5;
  text-align: center;
  font-family: Bacasime Antique, serif;
  background-image: url('../../assets/images/logo/matsya_manali_opacity_logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.about-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.aboutUsTitle {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 800;
  font-family: 'Ysabeau SC', sans-serif;
}

.divider {
  height: 3px;
  background-color: rgba(24, 157, 99, 0.538);
  width: 150px;
  margin-top: 10px;
}

@media screen and (max-width:900px) {
  .about-box {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .about-text {
    font-size: 1.2rem;
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .about-text {
    font-size: 1rem;
    width: 80%;
  }
}