body {
  margin: 0;
  padding: 0;
}

.footer {
  background: linear-gradient(to bottom, #2c2c2d, #050505);
  color: #fff;
  padding: 30px 20px;
  text-align: center;
  min-height: 100px;
  justify-content: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.social-links {
  margin-bottom: 20px;
}

.social-link {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 18px;
}

.social-link:hover {
  text-decoration: underline;
}

.footer-sections {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-us,
.quick-links {
  flex: 1 1 15%;
  max-width: 400px;
  margin-bottom: 20px;
}

h3 {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 5px;
}

ul li a {
  color: #fff;
  text-decoration: none;
}

ul li a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .footer-sections {
    flex-direction: column;
    align-items: center;
  }
}