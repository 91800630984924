.gallery-container {
    height: fit-content;
}

.carasoul-box {
    height: 30rem;
    margin-top: 2rem;
    width: 85%;
    border-radius: .5rem;

}

.carasoulInherited {}


.gallery-item {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.galleryText {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 800;
    font-family: 'Ysabeau SC', sans-serif;
}

.divider {
    height: 3px;
    /* background-color: black; */
    background-color: rgba(24, 157, 99, 0.538);
    width: 150px;
    margin-top: 10px;
}