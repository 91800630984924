.homeMainDiv {
  /* position: relative; */
  height: 100vh;
}

.video-background {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-body {
  /* color: white; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcomeToMatsyaManaliDiv {
  /* position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  opacity: 0;
  animation: fade-in 2s forwards; */
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeInAnimation 1.5s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.welcomeToMatsyaManaliText {
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Ysabeau SC', sans-serif;
  color: white;
}

.discoverGreatnessText {
  font-size: 1.5rem;
  text-transform: capitalize;
  text-align: center;
  /* font-family: 'Ysabeau SC', sans-serif; */
  font-family: Bacasime Antique, serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
  margin-top: 25px;
}

.bookNowText {
  color: white;
  font-family: 'Ysabeau SC', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 2rem;
  cursor: pointer;
}

.partnerImage {
  border-radius: 10px;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  cursor: pointer;
  animation: 0.3s ease-in-out slideIn;
  animation-fill-mode: forwards;
  opacity: 0
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .welcomeToMatsyaManaliText {
    font-size: 2.5rem;
  }

  .discoverGreatnessText {
    font-size: 1.2rem;
  }
}

@media(max-width:630px) {
  .discoverGreatnessText {
    font-size: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

}

@media screen and (max-width: 480px) {
  .welcomeToMatsyaManaliText {
    font-size: 2rem;
  }

  .discoverGreatnessText {
    font-size: 1rem;
  }
}