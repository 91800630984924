.aminities-container {
    padding-top: 2rem;
    height: fit-content;
}

.aminities-title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 800;
    font-family: 'Ysabeau SC', sans-serif;
}

.divider {
    height: 3px;
    background-color: rgba(24, 157, 99, 0.538);
    width: 150px;
    margin-top: 10px;
}

.aminitiesDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.aminitiesDescriptionMainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
    padding-right: 0.5rem;
    padding-left:0.5rem
}

.blackCircleImage {
    width: 1rem;
    height: 1rem;
}

.aminitiesName {
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Cormorant Garamond', serif;
    margin-left: 2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ovenDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.sheetsDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.assistanceDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.freeWifiDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.wifiAssistanceDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.ovenSheetsDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.beverageBagsDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
}

.bathrobesDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
}

.dentalDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
}

.hairDryerDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
}

.wifiDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.ovenComponentsDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.sheetsAndTowelsDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

@media (max-width:1050px) {
    .aminitiesName {
        margin-left: 1rem;
    }
}

@media (max-width:1130px) {
    .aminitiesDescriptionMainContainer {
        flex-direction: column;
    }

    .wifiAssistanceDiv {
        margin-top: 1rem;
        width: 80%;
    }

    .ovenSheetsDiv {
        width: 80%;
    }

    .assistanceDiv {
        justify-content: start;
    }

    .ovenDiv {
        justify-content: start;
    }

    .sheetsDiv {
        justify-content: start;
    }

    .freeWifiDiv {
        justify-content: start;
    }
}

@media (max-width:700px) {
    .aminitiesName {
        font-weight: 600;
        font-size: 1.5rem;
    }

    .blackCircleImage {
        width: 0.8rem;
        height: 0.8rem;
    }
}

@media(max-width:480px) {
    .aminitiesName {
        font-weight: 600;
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }
}

@media(max-width:380px) {

    .wifiAssistanceDiv {
        justify-content: start;
    }
}

@media (max-width:380px) {
    .ovenSheetsDiv {
        flex-direction: column;
    }

    .wifiAssistanceDiv {
        flex-direction: column;
    }

    .sheetsDiv {
        margin-top: 1rem;
    }

    .assistanceDiv {
        margin-top: 1rem;
    }
}

@media (max-width:340px) {}