.services-container {
  max-height: fit-content;
  margin-top: 40px;
}

.services-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;

}

.wifi-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fine-dine-box {
  /* margin: 2rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  text-align: center;
  margin-top: 20px;
  /* box-shadow: 0 2px 4px rgba(24, 157, 99, 0.538); */
  margin-bottom: 40px;
}

.free-parking-box {
  /* margin: 2rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  text-align: center;
  margin-top: 20px;
  /* box-shadow: 0 2px 4px rgba(24, 157, 99, 0.538); */
}

.bonfire-box {
  /* margin: 2rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  text-align: center;
  margin-top: 20px;
  /* box-shadow: 0 2px 4px rgba(24, 157, 99, 0.538); */
}

.panaromic-view-box {
  padding: 1rem;
  /* padding-right: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  text-align: center;
  margin-top: 20px;
  /* box-shadow: 0 2px 4px rgba(24, 157, 99, 0.538); */
}

.subTitle {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Cormorant Garamond', serif;
}

.description {
  margin-top: 1.5rem;
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 1.5;
  font-family: Bacasime Antique, serif;
  text-align: center;
}

.ser-image-box {
  margin-top: 1rem;
  height: 200px;
  width: 200px;
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.servicesImages {
  object-fit: cover;
  width: 150px;
  height: 150px;
  margin-top: 1rem
}

.servicesText {
  text-align: center;
  font-weight: 800;
  font-family: 'Ysabeau SC', sans-serif;
  font-size: 2.25rem;
}

.divider {
  height: 3px;
  /* background-color: black; */
  background-color: rgba(24, 157, 99, 0.538);
  width: 150px;
  margin-top: 10px;
}

@media (max-width:1050px) {
  .services-container {
    margin-top: 60px;
  }
}

@media (max-width:900px) {
  .services-container {
    margin-top: 40px;
  }

  .wifi-box {
    margin-top: 0px;
    padding-top: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .description {
    font-size: 1rem;
  }

  .subTitle {
    font-size: 1.5rem;
  }

}

@media screen and (max-width: 480px) {
  .description {
    font-size: .8rem;
  }

  .subTitle {
    font-size: 1.2rem;
  }
}